import { useState, useEffect, Fragment } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import moment from "moment";
import {
  Badge,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
  Table,
} from 'reactstrap';
import { formatDateUS } from '../../../../utils/commonFn';
import READ_MORE_ARROW from '../../../../assets/images/read-arrow.svg';

import {
  countWordsInString,
  moneyFormatter,
  trimStringUptoWords,
} from '../../../../utils/helper';
import { IAccountManagerPercentage } from '../create/IndexUpdatePlacement';
import {
  PLACEMENT_CONTACT,
  PLACEMENT_EDIT_NOTES,
} from '../gql';
import { IRecruiterPercentage } from "../../../../interfaces";
import EMPTY_ICON from '../../../../assets/images/empty-box.svg';
import { toast } from 'react-toastify';

import ViewMoreSummaryModal from '../../../../components/ViewMoreSummaryModal';
import InputField from '../../../../components/layout/InputField';
import { PAID, PERCENTAGE } from "../../../../utils/constant";

export default function ViewPlacementForAccounting({
  accountManagerList,
  recruiterList,
  viewData,
  refetch,
}) {
  const [noteToAccounting, setNoteToAccounting] = useState<string>('');
  const [placementNote, setPlacementNote] = useState<string>('');
  const [_placementContact, { data: _placementContactData }] =
    useLazyQuery(PLACEMENT_CONTACT);

  useEffect(() => {
    if (viewData) {
      setPlacementNote(viewData.placementNote);
      setNoteToAccounting(viewData?.noteToAccounting);
    }
    if (viewData?.placementType === 'CONTRACT') {
      _placementContact({ variables: { placementId: viewData?.id } });
    }

    // eslint-disable-next-line
  }, [viewData]);

  const [placementNoteModal, setPlacementNoteModal] = useState(false);
  const [notesToAccounting, setNotesToAccounting] = useState(false);
  const [addNoteModal, setAddNoteModal] = useState(false);

  const [_updatePlacementNotes, { loading }] =
    useMutation(PLACEMENT_EDIT_NOTES);

  const placementNoteToggle = () => {
    setPlacementNoteModal(!placementNoteModal);
  };

  const notesToAccountingToggle = () => {
    setNotesToAccounting(!notesToAccounting);
  };

  const addNoteToggle = () => {
    setAddNoteModal(!addNoteModal);
  };

  const _updatePlacementNotesHandler = async () => {
    const res = await _updatePlacementNotes({
      variables: {
        placementNoteUpdateDTO: {
          id: viewData?.id,
          placementNote,
          noteToAccounting,
        },
      },
    });

    if (res.data) {
      refetch();
      setAddNoteModal(false);
      toast.success('Placement note updated successfully');
    }
  };

  return (
    <Fragment>
      <div className="jobOrderRevamp placementDetail">
        <div className="d-flex align-items-center justify-content-between mb-2">
          <div className="d-flex align-items-center justify-content-between flex-wrap gap-10 w-100">
            <div className="d-flex align-items-center flex-wrap gap-10">
              <h5 className="noMarginHeading mr-2">Placement Detail</h5>

              <Badge
                color={
                  viewData?.placementStatus === 'ACCOUNTING_APPROVED' ||
                    viewData?.placementStatus === 'ACCOUNTING_CONFIRMED' ||
                    viewData?.placementStatus === 'ACCOUNTING_INVOICED' ||
                    viewData?.placementStatus === PAID
                    ? 'success'
                    : viewData?.placementStatus === 'PROSPECT'
                      ? 'warning'
                      : viewData?.placementStatus === 'DRAFT' ||
                        viewData?.placementStatus === 'ASSIGNMENT_COMPLETED' ||
                        viewData?.placementStatus === 'ASSIGNMENT_CONVERTED'
                        ? 'primary'
                        : viewData?.placementStatus === 'REJECTED_MANAGER' ||
                          viewData?.placementStatus === 'REJECTED_AM' ||
                          viewData?.placementStatus === 'REJECTED_ACCOUNTING' ||
                          viewData?.placementStatus === 'REJECTED_RECRUITER' ||
                          viewData?.placementStatus === 'PLACEMENT_CANCELLED' ||
                          viewData?.placementStatus === 'ASSIGNMENT_CANCELLED'
                          ? 'danger'
                          : viewData?.placementStatus === 'PENDING_MANAGER' ||
                            viewData?.placementStatus === 'PENDING_AM' ||
                            viewData?.placementStatus === 'PENDING_RECRUITER' ||
                            viewData?.placementStatus === 'PENDING_ACCOUNTING'
                            ? 'info'
                            : 'primary'
                }
                className="text-center"
              >
                {viewData?.placementStatus}
              </Badge>
            </div>

            <button
              className="buttonGenericStyle filledButton"
              onClick={() => {
                addNoteToggle();
              }}
            >
              Update Notes
            </button>
          </div>
        </div>

        <div>
          {viewData?.placementType === 'DIRECT_HIRE' ? (
            <Row>
              <Col lg="6" md="6" sm="12">
                <div className="d-flex justify-content-between mb-2 contentGridLabel">
                  <label>Invoice ID</label>

                  <h6>{viewData?.invoiceNumber || "--"}</h6>
                </div>

                <div className="d-flex justify-content-between mb-2 contentGridLabel">
                  <label>Placed Date</label>
                  
                  <h6>{viewData?.createdAt &&
                      formatDateUS(moment(viewData?.createdAt).format())}
                  </h6>
                </div>

                <div className="d-flex justify-content-between mb-2 contentGridLabel">
                  <label>Start Date</label>

                  <h6>
                    {viewData?.startDateString &&
                      formatDateUS(moment(viewData?.startDateString).format())}
                  </h6>
                </div>

                <div className="d-flex justify-content-between mb-2 contentGridLabel">
                  <label>Placement Source</label>
                  <h6>{viewData?.source || '--'}</h6>
                </div>
                <div className="d-flex justify-content-between mb-2 contentGridLabel">
                  <label>Placement Type</label>
                  <h6>{viewData?.placementType || '--'}</h6>
                </div>

                <div className="d-flex justify-content-between mb-2 contentGridLabel">
                  <label>Salary</label>
                  <h6>{moneyFormatter(viewData?.salaryAmount || 0)}</h6>
                </div>

                <div className="d-flex justify-content-between mb-2 contentGridLabel">
                  <label>Commission Type</label>
                  <h6>{viewData?.commissionType || '--'}</h6>
                </div>

                <div className="d-flex justify-content-between mb-2 contentGridLabel">
                  <label>Agreement</label>
                  <h6>{viewData?.agreement?.nickName || '--'}</h6>
                </div>
              </Col>

              <Col lg="6" md="6" sm="12">
                <div className="d-flex justify-content-between mb-2 contentGridLabel">
                  <label>Client Fee Rate</label>
                  <h6>
                    {viewData?.feeStructured
                      ? viewData?.commissionType === PERCENTAGE ? `${viewData?.feeStructured}%` : `${moneyFormatter(viewData?.feeStructured || 0)}`
                      : '--'}
                  </h6>
                </div>
                <div className="d-flex justify-content-between mb-2 contentGridLabel">
                  <label>Replacement Days</label>
                  <h6>{viewData?.replacementTerm || '--'}</h6>
                </div>
                <div className="d-flex justify-content-between mb-2 contentGridLabel">
                  <label>Payable Days</label>
                  <h6>{viewData?.payableTerm || '--'}</h6>
                </div>

                <div className="d-flex justify-content-between mb-2 contentGridLabel">
                  <label>Total Fee</label>
                  <h6>{moneyFormatter(viewData?.totalCommission || 0)}</h6>
                </div>

                <div className="d-flex justify-content-between mb-2 contentGridLabel">
                  <label>Adjustment Amount</label>
                  <h6>{moneyFormatter(viewData?.adjustmentTotal || 0)}</h6>
                </div>

                <div className="d-flex justify-content-between mb-2 contentGridLabel">
                  <label>Adjustment Reason</label>
                  <h6>{viewData?.adjustmentsReason || '--'}</h6>
                </div>

                <div className="d-flex justify-content-between mb-2 contentGridLabel">
                  <label>Net Fee</label>
                  <h6>{moneyFormatter(viewData?.netCommission || 0)}</h6>
                </div>
              </Col>
            </Row>
          ) : (
            <Row>
              <Col lg="6" md="6" sm="12">
                <div className="d-flex justify-content-between mb-2 contentGridLabel">
                  <label>Placement ID</label>

                  <h6>{viewData?.placementId}</h6>
                </div>
                <div className="d-flex justify-content-between mb-2 contentGridLabel">
                  <label>Start Date</label>

                  <h6>
                    {viewData?.startDateString &&
                      formatDateUS(moment(viewData?.startDateString).format())}
                  </h6>
                </div>

                <div className="d-flex justify-content-between mb-2 contentGridLabel">
                  <label>Assignment Duration (Weeks)</label>
                  <h6>
                    {viewData?.assignmentDuration
                      ? viewData?.assignmentDuration === 1
                        ? `${viewData?.assignmentDuration} week`
                        : `${viewData?.assignmentDuration} weeks`
                      : '--'}
                  </h6>
                </div>

                <div className="d-flex justify-content-between mb-2 contentGridLabel">
                  <label>Less ACA Cost</label>
                  <h6>
                    ({moneyFormatter(0.15)}
                    {`/hour`})
                  </h6>
                </div>

                <div className="d-flex justify-content-between mb-2 contentGridLabel">
                  <label>Company Bill Rate</label>
                  <h6>
                    {moneyFormatter(viewData?.billRate || 0)}
                    {`/hour`}
                  </h6>
                </div>

                <div className="d-flex justify-content-between mb-2 contentGridLabel">
                  <label>Less Employee Pay Rate</label>
                  <h6>
                    ({moneyFormatter(viewData?.payRate || 0)}
                    {`/hour`})
                  </h6>
                </div>

                <div className="d-flex justify-content-between mb-2 contentGridLabel">
                  <label>Estimated Less Payroll Burden</label>
                  <h6>
                    ({moneyFormatter(viewData?.estimatedLessPayrollBurden || 0)}
                    )
                  </h6>
                </div>

                <div className="d-flex justify-content-between mb-2 contentGridLabel">
                  <label>Less People 2.0 Fee</label>
                  <h6>
                    ({moneyFormatter(viewData?.peopleTwoFee || 0)}
                    /hour)
                  </h6>
                </div>
              </Col>

              <Col lg="6" md="6" sm="12">
                <div className="d-flex justify-content-between mb-2 contentGridLabel">
                  <label>Estimated Recruiter Profit</label>
                  <h6>
                    {moneyFormatter(viewData?.estimatedRecruiterProfit || 0)}
                    /hour
                  </h6>
                </div>

                <div className="d-flex justify-content-between mb-2 contentGridLabel">
                  <label>Insurance Cost</label>
                  <h6>{moneyFormatter(viewData?.insuranceCost || 0)}</h6>
                </div>

                <div className="d-flex justify-content-between mb-2 contentGridLabel">
                  <label>Markup Percentage</label>
                  <h6>{viewData?.markupPercentage?.toFixed(2) || '0'}%</h6>
                </div>

                <div className="d-flex justify-content-between mb-2 contentGridLabel">
                  <label>Overtime Bill Rate</label>
                  <h6>{moneyFormatter(viewData?.overtimeBillRate || 0)}</h6>
                </div>

                <div className="d-flex justify-content-between mb-2 contentGridLabel">
                  <label>Time Card Approver</label>
                  <h6>
                    {_placementContactData?.placementContact?.companyContact
                      ?.fullName || '--'}
                  </h6>
                </div>

                <div className="d-flex justify-content-between mb-2 contentGridLabel">
                  <label>Approx End Date</label>
                  <h6>
                    {viewData?.estimateEndDate &&
                      formatDateUS(viewData?.estimateEndDate)}
                  </h6>
                </div>
              </Col>
            </Row>
          )}

          <Row>
            <Col lg="6" md="6" sm="12">
              <div className="d-flex justify-content-between mb-2 contentGridLabel summarySection mt-0">
                <label>Placement Notes</label>

                <h6>
                  {viewData?.placementNote &&
                    countWordsInString(viewData?.placementNote, 20) ? (
                    <>
                      <h6 className="companyTextWidth">
                        {trimStringUptoWords(
                          viewData?.placementNote?.replace(/<[^>]*>?/gm, ''),
                          30
                        )}
                      </h6>
                      <button
                        type="button"
                        className="bg-transparent border-0"
                        onClick={placementNoteToggle}
                      >
                        Read More <img src={READ_MORE_ARROW} alt="arrow" />
                      </button>
                    </>
                  ) : (
                    <h6 className="companyTextWidth">
                      {viewData?.placementNote?.replace(/<[^>]*>?/gm, '') ||
                        '--'}
                    </h6>
                  )}
                </h6>
              </div>
            </Col>

            <Col lg="6" md="6" sm="12">
              <div className="d-flex justify-content-between mb-2 contentGridLabel summarySection mt-0">
                <label>Accounting Note</label>

                <h6>
                  {viewData?.noteToAccounting &&
                    countWordsInString(viewData?.noteToAccounting, 20) ? (
                    <>
                      <h6 className="companyTextWidth">
                        {trimStringUptoWords(
                          viewData?.noteToAccounting?.replace(/<[^>]*>?/gm, ''),
                          30
                        )}
                      </h6>
                      <button
                        type="button"
                        className="bg-transparent border-0"
                        onClick={notesToAccountingToggle}
                      >
                        Read More <img src={READ_MORE_ARROW} alt="arrow" />
                      </button>
                    </>
                  ) : (
                    <h6 className="companyTextWidth">
                      {viewData?.noteToAccounting?.replace(/<[^>]*>?/gm, '') ||
                        '--'}
                    </h6>
                  )}
                </h6>
              </div>
            </Col>
          </Row>
        </div>
      </div>

      <div className="jobOrderRevamp">
        <Row>
          <Col lg="5" md="6" sm="12" xs="12">
            <div className="d-flex align-items-center justify-content-between">
              <h5 className="mb-1">Account Manager Split</h5>
            </div>

            <div>
              {accountManagerList?.length ? (
                <div className="dark-table">
                  <Table
                    className="transparentTable"
                    style={{ borderCollapse: 'separate' }}
                  >
                    <thead>
                      <tr>
                        <th style={{ width: '40%' }}>Name</th>
                        <th style={{ width: '30%' }}>Credit%</th>
                        <th
                          style={{ width: '30%', textAlign: 'right' }}
                          align="right"
                        >
                          Billing Credit
                        </th>
                      </tr>
                    </thead>
                    {accountManagerList?.map(
                      (item: IAccountManagerPercentage, index: number) => {
                        return (
                          <tbody>
                            <tr>
                              <td>
                                <h6>{item?.accountManager?.label}</h6>
                              </td>
                              <td>
                                <h6>{item?.percentage}%</h6>
                              </td>
                              <td align="right">
                                <h6>
                                  {moneyFormatter(
                                    (viewData?.netCommission *
                                      0.5 *
                                      item?.percentage) /
                                    100 || 0
                                  )}
                                </h6>
                              </td>
                            </tr>
                          </tbody>
                        );
                      }
                    )}
                  </Table>
                </div>
              ) : (
                <div className="emptyPlaceholder">
                  <img src={EMPTY_ICON} alt="empty" />
                  Split for account manager is not added yet
                </div>
              )}
            </div>
          </Col>

          <Col lg="2" md="6" sm="12" xs="12"></Col>

          <Col lg="5" md="6" sm="12" xs="12">
            <div className="d-flex align-items-center justify-content-between">
              <h5 className="mb-1">Recruiters Split</h5>
            </div>

            <div>
              {recruiterList?.length ? (
                <div className="dark-table">
                  <Table
                    className="transparentTable"
                    style={{ borderCollapse: 'separate' }}
                  >
                    <thead>
                      <tr>
                        <th style={{ width: '40%' }}>Name</th>
                        <th style={{ width: '30%' }}>Credit%</th>
                        <th
                          style={{ width: '30%', textAlign: 'right' }}
                          align="right"
                        >
                          Billing Credit
                        </th>
                      </tr>
                    </thead>
                    {recruiterList?.map(
                      (item: IRecruiterPercentage, index: number) => {
                        return (
                          <tbody>
                            <tr>
                              <td>
                                <h6>{item?.recruiter?.label}</h6>
                              </td>
                              <td>
                                <h6>{item?.percentage}%</h6>
                              </td>
                              <td align="right">
                                <h6>
                                  {moneyFormatter(
                                    (viewData?.netCommission *
                                      0.5 *
                                      item?.percentage) /
                                    100 || 0
                                  )}
                                </h6>
                              </td>
                            </tr>
                          </tbody>
                        );
                      }
                    )}
                  </Table>
                </div>
              ) : (
                <div className="emptyPlaceholder">
                  <img src={EMPTY_ICON} alt="empty" />
                  Split for recruiter is not added yet
                </div>
              )}
            </div>
          </Col>
        </Row>
      </div>

      <Modal
        isOpen={addNoteModal}
        toggle={addNoteToggle}
        id="companySummaryModal"
      >
        <ModalHeader toggle={addNoteToggle} className="px-4">
          Placement Notes
        </ModalHeader>
        <ModalBody
          className="p-4"
          style={{ overflow: 'auto', maxHeight: '30rem' }}
        >
          <Row>
            <Col lg="6" md="6" sm="12">
              <InputField
                label="Placement Notes"
                inputtype="textarea"
                value={placementNote}
                onChange={(e) => setPlacementNote(e.target.value)}
                placeholder="Placement Notes"
                inputid="placementNote"
              />
            </Col>

            <Col lg="6" md="6" sm="12">
              <InputField
                label="Notes to Accounting"
                inputtype="textarea"
                value={noteToAccounting}
                onChange={(e) => setNoteToAccounting(e.target.value)}
                placeholder="Notes to Accounting"
                inputid="noteToAccounting"
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            onClick={addNoteToggle}
            className="buttonPrimary mr-3"
          >
            Cancel
          </button>
          <button
            type="button"
            className="buttonGenericStyle filledButton"
            onClick={_updatePlacementNotesHandler}
          >
            {loading && (
              <Spinner
                size="sm"
                style={{ color: '#eaa827' }}
                className="mr-2"
              />
            )}
            {'Add Note'}
          </button>
        </ModalFooter>
      </Modal>

      <ViewMoreSummaryModal
        toggle={placementNoteToggle}
        isOpen={placementNoteModal}
        data={viewData?.placementNote}
        heading="Placement Notes"
      />

      <ViewMoreSummaryModal
        toggle={notesToAccountingToggle}
        isOpen={notesToAccounting}
        data={viewData?.noteToAccounting}
        heading="Notes to Accounting"
      />
    </Fragment>
  );
}
